import {ProductUrl, Task} from "@co-common-libs/resources";
import {
  getContactLookup,
  getCurrentRole,
  getCustomerLookup,
  getDeliveryArray,
  getDeliveryLocationArray,
  getExtendedCustomerSettings,
  getLocationArray,
  getLocationLookup,
  getLocationStorageAdjustmentArray,
  getLocationStorageChangeArray,
  getLocationStorageStatusArray,
  getLocationTypeLookup,
  getMachineArray,
  getMachineLookup,
  getOrderLookup,
  getPickupArray,
  getPickupLocationArray,
  getPriceGroupLookup,
  getPriceItemArray,
  getPriceItemLookup,
  getProductGroupLookup,
  getProductLookup,
  getProjectArray,
  getProjectLookup,
  getReportingSpecificationLookup,
  getRouteTaskActivityOptionLookup,
  getRouteTaskArray,
  getRouteTaskLookup,
  getRouteTaskResultArray,
  getTaskFileArray,
  getTaskLookup,
  getTaskPhotoArray,
  getTimerArray,
  getTimerLookup,
  getTransportLogArray,
  getUnitLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {ValidationDialog} from "app-components";
import {
  ErrorEntry,
  InlinedTask,
  getErrors,
  getGenericPrimaryTimer,
  getReadonlyProductsFromTask,
  getTaskSecondaryTimerList,
  getWarnings,
  inlineTaskData,
  inlineTransportLogData,
} from "app-utils";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

export const ApproveTasksDialog = ({
  onCancel,
  onOk,
  open,
  tasksReadyForApproval,
}: {
  onCancel: () => void;
  onOk: (taskList: readonly InlinedTask[]) => void;
  open: boolean;
  tasksReadyForApproval: Task[];
}): JSX.Element => {
  const customerLookup = useSelector(getCustomerLookup);
  const machineLookup = useSelector(getMachineLookup);
  const orderLookup = useSelector(getOrderLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const projectLookup = useSelector(getProjectLookup);
  const reportingSpecificationLookup = useSelector(getReportingSpecificationLookup);
  const timerLookup = useSelector(getTimerLookup);
  const transportLogArray = useSelector(getTransportLogArray);
  const deliveryArray = useSelector(getDeliveryArray);
  const deliveryLocationArray = useSelector(getDeliveryLocationArray);
  const pickupArray = useSelector(getPickupArray);
  const pickupLocationArray = useSelector(getPickupLocationArray);
  const timerArray = useSelector(getTimerArray);
  const locationArray = useSelector(getLocationArray);
  const locationStorageAdjustmentArray = useSelector(getLocationStorageAdjustmentArray);
  const locationStorageChangeArray = useSelector(getLocationStorageChangeArray);
  const locationStorageStatusArray = useSelector(getLocationStorageStatusArray);
  const locationTypeLookup = useSelector(getLocationTypeLookup);
  const machineArray = useSelector(getMachineArray);
  const priceItemArray = useSelector(getPriceItemArray);
  const projectArray = useSelector(getProjectArray);
  const routeTaskArray = useSelector(getRouteTaskArray);
  const unitLookup = useSelector(getUnitLookup);
  const routeTaskActivityOptionLookup = useSelector(getRouteTaskActivityOptionLookup);
  const routeTaskLookup = useSelector(getRouteTaskLookup);
  const routeTaskResultArray = useSelector(getRouteTaskResultArray);
  const taskFileArray = useSelector(getTaskFileArray);
  const taskPhotoArray = useSelector(getTaskPhotoArray);
  const contactLookup = useSelector(getContactLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const locationLookup = useSelector(getLocationLookup);
  const productGroupLookup = useSelector(getProductGroupLookup);
  const taskLookup = useSelector(getTaskLookup);

  const customerSettings = useSelector(getExtendedCustomerSettings);
  const currentRole = useSelector(getCurrentRole);
  const userIsManager = !!currentRole?.manager;
  const intl = useIntl();
  const [taskWithDataList, setTaskWithDataList] = useState<InlinedTask[] | undefined>();
  const [taskIssueMap, setTaskIssueMap] = useState<
    | Map<
        string,
        {
          errors: readonly ErrorEntry[];
          warnings: readonly ErrorEntry[];
        }
      >
    | undefined
  >();
  useEffect(() => {
    if (open) {
      const taskDataLookups = {
        contactLookup,
        customerLookup,
        machineLookup,
        orderLookup,
        priceGroupLookup,
        priceItemLookup,
        productLookup,
        projectLookup,
        reportingSpecificationLookup,
        timerLookup,
        workTypeLookup,
      };
      const tasks = _.sortBy(tasksReadyForApproval, (task) => task.workFromTimestamp).map((task) =>
        inlineTaskData(task, taskDataLookups),
      );
      const taskURLSet = new Set(tasksReadyForApproval.map((task) => task.url));
      const transportLogWithDataList = transportLogArray
        .filter((transportLog) => taskURLSet.has(transportLog.task))
        .map((transportLog) =>
          inlineTransportLogData(transportLog, {
            deliveryArray,
            deliveryLocationArray,
            pickupArray,
            pickupLocationArray,
          }),
        );
      const genericPrimaryTimer = getGenericPrimaryTimer(timerArray);
      const taskIssues = new Map();
      tasks.forEach((task) => {
        const taskURL = task.url;
        const transportLog = transportLogWithDataList.find((t) => t.task === taskURL);
        const secondaryTimers = new Set(
          getTaskSecondaryTimerList(task, customerSettings, {
            machineLookup,
            priceGroupLookup,
            timerArray,
            workTypeLookup,
          }),
        );

        const productsWithLogData: Set<ProductUrl> = new Set();
        const readonlyProducts: Set<ProductUrl> = getReadonlyProductsFromTask(
          task,
          productLookup,
          unitLookup,
          reportingSpecificationLookup,
        );

        const warnings: ErrorEntry[] = getWarnings(
          {
            genericPrimaryTimer,
            locationArray,
            locationLookup,
            locationStorageAdjustmentArray,
            locationStorageChangeArray,
            locationStorageStatusArray,
            locationTypeLookup,
            machineArray,
            machineLookup,
            priceGroupLookup,
            priceItemArray,
            priceItemLookup,
            productGroupLookup,
            productLookup,
            productsWithLogData,
            projectArray,
            readonlyProducts,
            routeTaskArray,
            secondaryTimers,
            task,
            timerArray,
            transportLog,
            unitLookup,
            userIsManager,
            workTypeLookup,
          },
          customerSettings,
          intl,
        );
        const errors: readonly ErrorEntry[] = getErrors(
          {
            genericPrimaryTimer,
            locationArray,
            machineArray,
            priceGroupLookup,
            priceItemArray,
            priceItemLookup,
            productGroupLookup,
            productLookup,
            productsWithLogData,
            projectArray,
            readonlyProducts,
            routeTaskActivityOptionLookup,
            routeTaskArray,
            routeTaskLookup,
            routeTaskResultArray,
            secondaryTimers,
            task,
            taskFileArray,
            taskPhotoArray,
            transportLog,
            unitLookup,
            userIsManager,
            workTypeLookup,
          },
          customerSettings,
          intl,
          false,
          false,
        );
        taskIssues.set(taskURL, {errors, warnings});
      });
      setTaskIssueMap(taskIssues);
      setTaskWithDataList(tasks);
    }
  }, [
    contactLookup,
    currentRole,
    customerLookup,
    customerSettings,
    deliveryArray,
    deliveryLocationArray,
    intl,
    locationArray,
    locationLookup,
    locationStorageAdjustmentArray,
    locationStorageChangeArray,
    locationStorageStatusArray,
    locationTypeLookup,
    machineArray,
    machineLookup,
    open,
    orderLookup,
    pickupArray,
    pickupLocationArray,
    priceGroupLookup,
    priceItemArray,
    priceItemLookup,
    productGroupLookup,
    productLookup,
    projectArray,
    projectLookup,
    reportingSpecificationLookup,
    routeTaskActivityOptionLookup,
    routeTaskArray,
    routeTaskLookup,
    routeTaskResultArray,
    taskFileArray,
    taskLookup,
    taskPhotoArray,
    tasksReadyForApproval,
    timerArray,
    timerLookup,
    transportLogArray,
    unitLookup,
    userIsManager,
    workTypeLookup,
  ]);

  return (
    <ValidationDialog
      open={open}
      taskIssueMap={taskIssueMap}
      taskList={taskWithDataList}
      onCancel={onCancel}
      onOk={onOk}
    />
  );
};
