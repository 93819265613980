import {ReadonlyConfig} from "@co-common-libs/config";
import {
  ActiveEconomySystemIntegrationKind,
  EconomySystemIntegrationKindPart,
  getEconomySystemIntegrationKind,
} from "../get-economy-system-integration-kind";
import {liveSyncResource} from "../live-sync-resource";

type ExtendedProductGroupSettingsPart = Pick<ReadonlyConfig, "canEditProductGroups"> &
  EconomySystemIntegrationKindPart;

export interface ExtendedProductGroupSettings {
  canImport: boolean;
  canManage: boolean;
}

export function extendedProductGroupSettings(
  config: ExtendedProductGroupSettingsPart,
): ExtendedProductGroupSettings {
  const {canEditProductGroups} = config;
  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);

  const integrationAllowsImport =
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC;

  const integrationAllowsManage =
    economySystemIntegrationKind !== ActiveEconomySystemIntegrationKind.LIVE_SYNC ||
    (economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC &&
      liveSyncResource(config, "productGroup"));

  return {
    canImport: integrationAllowsImport && canEditProductGroups,
    canManage: integrationAllowsManage && canEditProductGroups,
  };
}
