import {ReadonlyConfig} from "@co-common-libs/config";
import {Role} from "@co-common-libs/resources";
import {roleIsManager} from "@co-common-libs/utils";
import {
  ActiveEconomySystemIntegrationKind,
  EconomySystemIntegrationKindPart,
  getEconomySystemIntegrationKind,
} from "../get-economy-system-integration-kind";
import {liveSyncResource} from "../live-sync-resource";

type SwallowSettings = Pick<ReadonlyConfig, "createCustomer">;

type ForwardSettings = Pick<
  ReadonlyConfig,
  "canEditCustomerVATNumber" | "customerRequiredFields" | "economicAutogeneratedCustomerNumbers"
>;

export type ExtendedCustomerSettingsPart = SwallowSettings &
  ForwardSettings &
  EconomySystemIntegrationKindPart;

export interface ExtendedCustomerSettings extends ForwardSettings {
  canManage: boolean;
  economicAutogeneratedCustomerNumbers: boolean;
  liveSyncWithThirdParty: boolean;
}

export function extendedCustomerSettings(
  config: ExtendedCustomerSettingsPart,
  role: Pick<Role, "manager" | "projectManager"> | null,
): ExtendedCustomerSettings {
  const {
    canEditCustomerVATNumber,
    createCustomer,
    customerRequiredFields,
    economicAutogeneratedCustomerNumbers,
  } = config;
  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);

  const isManager = roleIsManager(role);

  const roleCanManage = createCustomer === "all" || (createCustomer === "manager" && isManager);

  return {
    canEditCustomerVATNumber,
    canManage: roleCanManage,
    customerRequiredFields,
    economicAutogeneratedCustomerNumbers,
    liveSyncWithThirdParty:
      liveSyncResource(config, "customer") &&
      economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC,
  };
}
