import {SettingID} from "@co-common-libs/config";
import {EntryData, GenericMultiSelectionSearchDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {SyncedResourceHandleKey, syncedResourceHandleKeys} from "integration-constants";
import {upperFirst} from "lodash";
import React, {useCallback, useMemo} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

interface SettingDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function SelectSyncedResources(props: SettingDialogProps): JSX.Element {
  const {onClose, open, settingID} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const selectedResources: SyncedResourceHandleKey[] = useMemo(
    () => settingEntry?.data || [],
    [settingEntry?.data],
  );

  const intl = useIntl();
  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleProductDialogOk = useCallback(
    (newlySelectedResources: ReadonlySet<SyncedResourceHandleKey>) => {
      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: [...newlySelectedResources]},
          ]),
        );
      }
      onClose();
    },
    [currentUserURL, dispatch, onClose, settingEntry],
  );

  const baseChoices: EntryData<SyncedResourceHandleKey>[] = useMemo(() => {
    return syncedResourceHandleKeys.map((resource) => {
      return {
        category: "standard",
        identifier: resource,
        primaryText: upperFirst(resource),
        readonly: resource !== "project", // TODO(mr): add translations
        searchFields: [
          {
            label: upperFirst(resource),
            priority: 10,
            text: resource,
          },
        ],
      };
    });
  }, []);

  return (
    <GenericMultiSelectionSearchDialog<SyncedResourceHandleKey>
      data={baseChoices}
      includeSelectAll={false}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      open={open}
      searchTitle={intl.formatMessage({defaultMessage: "Søg"})}
      selected={new Set(selectedResources)}
      sorting="PRIMARY_IDENTIFIER"
      title={intl.formatMessage({defaultMessage: "Vælg resurser der synkroniseres"})}
      onCancel={onClose}
      onOk={handleProductDialogOk}
    />
  );
}
