import {Task} from "@co-common-libs/resources";
import {useCallWithFalse, useCallWithTrue, useResettingState} from "@co-frontend-libs/utils";
import {useCustomerFromTask} from "app-utils";
import React from "react";
import {ChangeCustomerForTaskWizard} from "./change-customer-for-task-wizard";
import {CustomerApproveDialog} from "./customer-approve-dialog";

interface CustomerApproveOrChangeDialogProps {
  allowChangeCurrentTaskOnly: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  open: boolean;
  task: Task;
}

/**
 * Approve or change/merge customer for task
 */
export const CustomerApproveOrChangeDialog = React.memo(function CustomerApproveOrChangeDialog(
  props: CustomerApproveOrChangeDialogProps,
): JSX.Element | null {
  const {allowChangeCurrentTaskOnly, onCancel, onSuccess, open, task} = props;
  const [changeCustomerWizardOpen, setChangeCustomerWizardOpen] = useResettingState<boolean>(
    false,
    open,
  );
  const setChangeCustomerWizardOpenTrue = useCallWithTrue(setChangeCustomerWizardOpen);
  const setChangeCustomerWizardOpenFalse = useCallWithFalse(setChangeCustomerWizardOpen);

  const customer = useCustomerFromTask(task);

  console.assert(customer !== null);

  return customer ? (
    <>
      <ChangeCustomerForTaskWizard
        allowChangeCurrentTaskOnly={allowChangeCurrentTaskOnly}
        open={open && changeCustomerWizardOpen}
        task={task}
        onCancel={onCancel}
        onFailure={setChangeCustomerWizardOpenFalse}
        onSuccess={onSuccess}
      />

      <CustomerApproveDialog
        customer={customer}
        open={open && !changeCustomerWizardOpen}
        onCancel={onCancel}
        onChange={setChangeCustomerWizardOpenTrue}
        onFailure={onCancel}
        onSuccess={onSuccess}
      />
    </>
  ) : null;
});
