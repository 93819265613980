import {Customer, urlToId} from "@co-common-libs/resources";
import {ResponseWithData, jsonFetch} from "@co-frontend-libs/utils";
import {createResourceUrl, updateAndUploadResourceUrl, updateResourceUrl} from "api-endpoint-urls";
import {pick} from "lodash";
import {CustomerFormMember} from "../customer-form/types";

type RequestCustomer = Pick<
  Customer,
  "active" | "address" | "billingEmail" | "city" | "name" | "phone" | "postalCode" | "vatNumber"
> & {c5Account: string | null};

function transformCustomerForRequest(
  customer: Pick<Customer, CustomerFormMember>,
): RequestCustomer {
  return {
    ...pick(
      customer,
      "active",
      "address",
      "billingEmail",
      "city",
      "name",
      "phone",
      "postalCode",
      "vatNumber",
    ),
    c5Account: customer.c5_account,
  } as RequestCustomer;
}

export function updateExternalCustomer(
  customer: Pick<Customer, CustomerFormMember | "url">,
  abortSignal?: AbortSignal | undefined,
): Promise<ResponseWithData> {
  return jsonFetch(
    updateResourceUrl("customer", urlToId(customer.url)),
    "PUT",
    transformCustomerForRequest(customer),
    abortSignal,
  );
}

export function updateAndUploadCustomer(
  customer: Pick<Customer, CustomerFormMember | "url">,
  abortSignal?: AbortSignal | undefined,
): Promise<ResponseWithData> {
  return jsonFetch(
    updateAndUploadResourceUrl("customer", urlToId(customer.url)),
    "POST",
    transformCustomerForRequest(customer),
    abortSignal,
  );
}

export function createCustomer(
  customer: Pick<Customer, CustomerFormMember>,
  abortSignal?: AbortSignal | undefined,
): Promise<ResponseWithData> {
  return jsonFetch(
    createResourceUrl("customer"),
    "POST",
    transformCustomerForRequest(customer),
    abortSignal,
  );
}
