import {SettingsGroupID} from "./settings-groups";
import {Config} from "./types";

export type SettingID = keyof Config;

export const settingGroupRelations: {
  readonly [S in SettingID]: SettingsGroupID;
} = {
  absenceCalendar: "kalenderFravrModul",
  absenceCalendarBlockColor: "kalenderFravr",
  absenceCalendarDisplayLabel: "kalenderFravr",
  absenceTypeColors: "lnFravrstyper",
  absenceTypeLabels: "lnFravrstyper",
  absenceTypesAccessibleToEmployees: "lnFravrstyper",
  absenceTypeShortLabels: "lnFravrstyper",
  absenceTypesVisibleOnFullscreenAbsenceCalendar: "systemInfoskrm",
  absenceWarningDisabledFor: "lnFravrstyper",
  addEditLogLocationSkipsCustomerSelection: "logGenerel",
  addMaterialNotesToProjectCostActivity: "eConomicGenereltProjekt",
  addNewAgricultureTaskAsDefault: "ukendtGenerelt",
  addNewContractorTaskAsDefault: "ukendtGenerelt",
  addNewContractorTaskAsDefaultGroup: "ukendtGenerelt",
  address: "virksomhedStamoplysninger",
  adjustBilledMinutes: "dagsedlerGenerelt",
  adminCanCreateCustomerTask: "opgaverGenerelt",
  allowCustomerTaskEmployeeChange: "opgaverGenerelt",
  allowCustomerTaskMachineChange: "opgaverGenerelt",
  allowDuplicateProductUses: "opgaverMateriel",
  allowMachineOperatorChangeOnTaskInfoPage: "opgaverInfo",
  allowMachineOperatorToEditLocationLocationType: "indstillingerLokationer",
  allowMachineOperatorToEditTaskDate: "opgaverDetaljer",
  allowMoreThanTwoMachinesForDepartments: "systemAfdeling",
  allowOrderReferenceNumberEdit: "ordreGenerelt",
  allowSeniorMachineOperatorsToManageFoodBookings: "madbestillingGenerelt",
  allowTakingPendingTasksFromOthers: "opgaverAfslutningsKnapper",
  allowTransportlogAmountMismatch: "logGammel",
  alternativeMaterialsMobileLayout: "opgaverMateriel",
  alwaysAllowEditingOrderReferenceNumber: "ordreDetalje",
  alwaysAllowEditingOrderWorkplace: "ordreGenerelt",
  alwaysAllowManagerTaskAndOrderNotesEdit: "ordreDetalje",
  alwaysAllowManagerTaskPhotosEdit: "opgaverFoto",
  alwaysAllowMoreThanTwoMachines: "systemGenerelt",
  alwaysUseLogTab: "opgaverLog",
  anniversariesEnabled: "medarbejdereProfil",
  askForPriceGroupSelectionAfterWorkTypeSelection: "ordreDetaljerOpgavetabel",
  askRegardingMissingBreakOnExternalTaskCompletion: "opgaverUdfrtDialog",
  askRegardingMissingBreakOnInternalTaskCompletion: "opgaverInternUdfrtDialog",
  autoAddSprayLogFor: "logGammel",
  autoAddTransportLogFor: "logGammel",
  autoAddYieldLogFor: "logGammel",
  autoComputedSalaryVouchers: "minArbejdstidModul",
  autoCopyMaterialNoteToSupplementingProductNote: "opgaverMateriel",
  autoCreateLogPrint: "logGenerel",
  autoFillReferenceNumberWithCustomerAccount: "ordreDetalje",
  autoFillUnregisteredMinutesOnStart: "systemGenerelt",
  autoInsertOrderDateAsNewTaskDate: "ordreDetaljerOpgavetabel",
  autoInsertSearchStringAsNewCustomerName: "kunderGenereltOpret",
  autoSupplementingProducts: "opgaverMateriel",
  availabilityWeekdays: "minDagseddelRdighed",
  availabilityWeekdaysAskFromDay: "minDagseddelRdighed",
  availabilityWeekdaysAskFromHour: "minDagseddelRdighed",
  availabilityWeekends: "minDagseddelRdighed",
  availabilityWeekendsAskFromDay: "minDagseddelRdighed",
  backgroundPositionInterval: "kortGenerelt",
  barcodeScannerMachineDialog: "stregkodeScanner",
  barcodeScannerProductDialog: "stregkodeScanner",
  billedBreaks: "lnGenerelt",
  bookkeepingDayHideBlankPriceItems: "dagsedlerGenerelt",
  bookkeepingDayHideBlankProducts: "dagsedlerGenerelt",
  bookkeepingDayLinePerTimer: "dagsedlerGenerelt",
  bookkeepingDayPDF: "dagsedlerUdskrift",
  bookkeepingDayRespectNoteLineBreaks: "dagsedlerNoter",
  bookkeepingListColumns: "dagsedlerGenerelt",
  bookkeepingMachineNames: "dagsedlerGenerelt",
  bookkeepingWorkplaceAddressReplaceName: "dagsedlerGenerelt",
  bookkeepingWorkTypeAsWorkTypeAndVariant: "dagsedlerGenerelt",
  brugerdataCreateOrderButton: "brugerDataGenerelt",
  brugerdataMaterialProductGroupRanges: "brugerDataGenerelt",
  brugerdataPriceitemProductGroupRanges: "brugerDataGenerelt",
  brugerdataShowMachineOperatorAlias: "brugerDataFaktura",
  brugerdataSync: "brugerDataModul",
  brugerdataSyncOnCompleted: "brugerDataGenerelt",
  brugerdataSyncOnValidated: "brugerDataFaktura",
  c5Sync: "c5Generelt",
  calendarForMachineOperators: "kalenderMedarbejderGenerelt",
  calendarPlannedTasksBehindMeasured: "kalenderMedarbejderGenerelt",
  calendarPlanningFunctions: "kalenderMedarbejderGenerelt",
  calendarShowEmployeeAlias: "kalenderMedarbejderKolonner",
  calendarShowEmployeeName: "kalenderMedarbejderKolonner",
  calendarShowEmployeeNumber: "kalenderMedarbejderKolonner",
  calendarShowMaterialsWithUnits: "kalenderMedarbejderOpgaveBlok",
  calendarShowWorkTypeNumber: "kalenderMedarbejderOpgaveBlok",
  canAddProject: "projekterGenerelt",
  canChangeTransportLogTime: "logGammel",
  canCreateCultures: "indstillingerKultur",
  canCreateExternalWorkType: "indstillingerOversigt",
  canCreateMachines: "indstillingerMaskiner",
  canCreatePriceGroups: "indstillingerOversigt",
  canCreatePriceItems: "indstillingerEgneOprettedePrislinjer",
  canEditAllUserDataOnProfilePage: "medarbejdereProfil",
  canEditCultures: "indstillingerKultur",
  canEditCustomerVATNumber: "kunderDetaljer",
  canEditExternalWorkType: "indstillingerOversigt",
  canEditInternalWorkTypes: "indstillingerOversigt",
  canEditMachines: "indstillingerMaskiner",
  canEditPriceGroups: "indstillingerOversigt",
  canEditPriceItemActive: "indstillingerPrislinjer",
  canEditPriceItemBillable: "indstillingerPrislinjer",
  canEditPriceItemID: "indstillingerEgneOprettedePrislinjer",
  canEditPriceItemInputConditions: "indstillingerPrislinjer",
  canEditPriceItemName: "indstillingerEgneOprettedePrislinjer",
  canEditPriceItemOnlyVisibleOnOverview: "indstillingerPrislinjer",
  canEditPriceItemProduct: "indstillingerEgneOprettedePrislinjer",
  canEditPriceItemRelevantForExecution: "indstillingerPrislinjer",
  canEditPriceItems: "indstillingerPrislinjer",
  canEditPriceItemUnit: "indstillingerEgneOprettedePrislinjer",
  canEditPriceItemUseManualDistribution: "indstillingerPrislinjer",
  canEditProductGroups: "indstillingerVarer",
  canEditProducts: "indstillingerVarer",
  canEditProjectDistance: "projekterGenerelt",
  canEditProjects: "projekterGenerelt",
  canEditUnits: "indstillinerEnheder",
  checkoutHideInactive: "displayGenerelt",
  chooseWorkTypesOnCustomerJournal: "kunderDetaljer",
  colorInternalTaskWorkTypesOnEmployeeCalendar: "kalenderMedarbejderOpgaveBlok",
  colorUnscheduledCalendar: "kalenderOrdre",
  colorWorkTypesOnEmployeeCalendar: "kalenderMedarbejderOpgaveBlok",
  companyName: "virksomhedStamoplysninger",
  completeTaskAsInternal: "opgaverAfslutningsKnapper",
  completeTaskAsInternalTargetDate: "opgaverAfslutningsKnapper",
  computedSalaryVoucherStartDay: "minArbejdstidOversigt",
  concurrentTasksAllowed: "opgaverGenerelt",
  concurrentTasksAllowedForDepartments: "opgaverGenerelt",
  concurrentTasksAllowedForWorkTypes: "opgaverGenerelt",
  consultantNotes: "omDenneKundeOpstning",
  contactMail: "virksomhedKontaktoplysninger",
  contactPerson: "virksomhedKontaktoplysninger",
  contactPhone: "virksomhedKontaktoplysninger",
  contactsMayHaveCellphone: "kunderDetaljer",
  continuationCopyFields: "opgaverAfslutningsKnapper",
  continuationTaskTargetDate: "opgaverAfslutningsKnapper",
  conversionFieldDecimals: "opgaverMateriel",
  createCostLines: "economySystemSendTaskData",
  createCustomer: "kunderGenereltOpret",
  createSalesLines: "economySystemSendTaskData",
  customerAliasEdit: "kunderDetaljer",
  customerApprovalGate: "kunderGenereltOpret",
  customerCreateInvoicesSetup: "kunderDetaljer",
  customerFields: "kunderMarker",
  customerFileUpload: "kunderDetaljer",
  customerJournal: "kunderDetaljer",
  customerOrderListNewestFirst: "ordreOrdreliste",
  customerOrderOverview: "kunderOrdreoverblik",
  customerProjectManager: "kunderDetaljer",
  customerRequiredFields: "kunderGenereltOpret",
  customerStatsVisibleToAll: "kunderStatistik",
  customerTaskAutoWorkTypePreviousMachines: "opgaverDetaljer",
  customerTaskCreationWizard: "opgaverOprettelseWizard",
  customOfficeProductVariant: "virksomhedProdukt",
  cvr: "virksomhedStamoplysninger",
  dayEndRounding: "lnrapportGenerelt",
  daysAbsenceTypes: "lnFravrstyper",
  daysIntoTheFutureVisibleToMachineOperator: "opgaverListeEgne",
  dayStartRounding: "lnrapportGenerelt",
  defaultTaskEmployee: "opgaverGenerelt",
  departmentExtraTimers: "tidsknapperGenerelt",
  departments: "systemAfdeling",
  dinnerBookingLocations: "madbestillingGenerelt",
  dinnerBookings: "madbestillingModul",
  dinnerBookingsShowThumbDialog: "madbestillingGenerelt",
  disabledMachines: "indstillingerOversigt",
  disabledWorkTypes: "indstillingerOversigt",
  displayCustomerContacts: "kunderDetaljer",
  displayProjectsPageMenu: "projekterModul",
  distributionTableAdjustTimeMethod: "dagsedlerGenerelt",
  dontShowTransportLogDialog: "logGammel",
  durationFormat: "systemGenerelt",
  economicAppendMaterialNotesToInvoiceLineDescription: "eConomicFaktura",
  economicAutogeneratedCustomerNumbers: "kunderGenereltOpret",
  economicCompanyIdentificationNumber: "eConomicModul",
  economicCustomerDetailsOnTransportlogOnInvoice: "eConomicFaktura",
  economicCustomerGroup: "kunderGenereltOpret",
  economicDefaultPaymentTerms: "eConomicFaktura",
  economicEnableMachinesDepartmentSync: "eConomicGenerelt",
  economicEnableMachinesProjectEmployeesSync: "eConomicGenereltProjekt",
  economicEnableProjectActivitiesImport: "eConomicGenereltProjekt",
  economicEnableUploadUsersToProjectEmployees: "eConomicGenereltProjekt",
  economicEnableWorkplaceImport: "eConomicGenerelt",
  economicEnableWorkTypeFromProductGroupImport: "eConomicGenerelt",
  economicFuelSurchargeLinesAtBottom: "braendstoftillaegEConomic",
  economicInvoiceEmployeeNotes: "eConomicFaktura",
  economicInvoiceIncludeTotalTaskTime: "eConomicFaktura",
  economicInvoiceManagerNotes: "eConomicFaktura",
  economicInvoicePerOrderReferenceNumber: "eConomicFaktura",
  economicInvoicePerProject: "eConomicFakturaProjekt",
  economicInvoicePerWorkplace: "eConomicFaktura",
  economicInvoicePerWorkplacePickupLocationFallback: "eConomicFaktura",
  economicInvoiceProjectNumberAndNameAsOtherReference: "eConomicFakturaProjekt",
  economicInvoicePullerAsDeparment: "eConomicFaktura",
  economicInvoicesForProjectTasks: "eConomicFakturaProjekt",
  economicInvoiceShowOrderWorkplaceNameAsOtherRef: "eConomicFaktura",
  economicInvoiceShowTaskWorkplaceNameAsOtherRef: "eConomicFaktura",
  economicInvoiceWorkplaceAddressReplaceName: "eConomicFaktura",
  economicMaterialProductGroups: "opgaverMateriel",
  economicMergLinesPerOrder: "eConomicFaktura",
  economicPrefixCustomerContactNameWith: "eConomicFaktura",
  economicPrefixMachineOperatorNameOrAliasWith: "eConomicFaktura",
  economicPriceitemProductGroupRanges: "eConomicGenerelt",
  economicProjectsApproveTimesOnTransfer: "eConomicGenereltProjekt",
  economicProjectsIncludeNotes: "eConomicFakturaProjekt",
  economicProjectsMachineEmployeeGroups: "eConomicGenereltProjekt",
  economicProjectsMachineEmployeeNumberOffset: "eConomicGenereltProjekt",
  economicProjectsMachineHoursActivity: "eConomicGenereltProjekt",
  economicProjectsManHoursActivity: "eConomicGenereltProjekt",
  economicProjectsManHoursMachines: "eConomicGenereltProjekt",
  economicProjectsProductUseCostActivity: "eConomicGenereltProjekt",
  economicProjectsProductUseCostEmployee: "eConomicGenereltProjekt",
  economicRouteTaskStartStopOnInvoice: "eConomicFaktura",
  economicSeperateDatesWithBlankLines: "eConomicFaktura",
  economicSeperateNoteLines: "eConomicFaktura",
  economicSeperateTasksWithBlankLines: "eConomicFaktura",
  economicShowCustomerContactName: "eConomicFaktura",
  economicShowFieldUse: "eConomicFaktura",
  economicShowFieldUseCrop: "eConomicFaktura",
  economicShowGenericTransportlogOnInvoice: "eConomicFaktura",
  economicShowMachineName: "eConomicFaktura",
  economicShowMachineOperatorAlias: "eConomicFaktura",
  economicShowMachineOperatorAliasOrNameWithoutLineBreak: "eConomicFaktura",
  economicShowMachineOperatorName: "eConomicFaktura",
  economicShowOrderReferenceNumber: "eConomicFaktura",
  economicShowPickupLocation: "eConomicFaktura",
  economicShowTaskReferenceNumber: "eConomicFaktura",
  economicShowTransportlogOnInvoice: "eConomicFaktura",
  economicShowWorkPlaceOrDeliveryLocation: "eConomicFaktura",
  economicSubtractDowntimeIdentifiers: "eConomicFaktura",
  economicSync: "eConomicModul",
  economicSyncListColumns: "eConomicGenerelt",
  economicTaskDepartmentImport: "eConomicGenerelt",
  economicUploadMachinesToProjectEmployeeGroup: "eConomicGenereltProjekt",
  economicUploadUsersToProjectEmployeeGroup: "eConomicGenereltProjekt",
  economicUploadWorkPlaces: "eConomicFakturaProjekt",
  economicWorktypeDepartmentImport: "eConomicGenerelt",
  economicZeroBlank: "eConomicFaktura",
  effectiveTimeLabel: "udgede",
  emailFromAddress: "virksomhedEmail",
  emailReplyToAddress: "virksomhedEmail",
  employeeCalendarManualOrderPerUser: "kalenderMedarbejderKolonner",
  employeeCanAccessAppendicesPage: "bilagGenerelt",
  employeeLabelVariant: "medarbejdereProfil",
  employeeSameDayTasksWarning: "opgaverDetaljer",
  enableAccommodationAllowanceRegistrationOnInternalTasks: "opgaverUdfrtDialog",
  enableAddProducts: "opgaverMateriel",
  enableAppendicesPage: "bilagModul",
  enableCalendarDepartmentFilter: "kalenderMedarbejderGenerelt",
  enableCalendarTaskClickWhenTaskPopupIsOpen: "kalenderMedarbejderOpgaveBlok",
  enableCalendarTaskInfoPopup: "kalenderMedarbejderOpgaveBlok",
  enableCalendarWorkTypeFilter: "kalenderMedarbejderGenerelt",
  enableConvertInternalToCustomerTask: "opgaverInternAfslutningsKnapper",
  enableCustomerCellphone: "kunderDetaljer",
  enableCustomerContacts: "kunderKontakter",
  enableCustomerSwitch: "opgaverInfo",
  enableDocuments: "dokumenterModul",
  enableDoneAtEstimateField: "opgaverTid",
  enableEmployeeCalendarManualOrder: "kalenderMedarbejderKolonner",
  enableExternalTaskDepartmentField: "systemAfdeling",
  enableExternalTaskWorkTypeAndVariantSwitch: "opgaverGenerelt",
  enableExtraWorkButton: "opgaverAfslutningsKnapper",
  enableFieldsSettingsPage: "kunderMarker",
  enableGPSList: "dagsedlerGps",
  enableInternalTaskDepartmentField: "systemAfdeling",
  enableInvoiceCorrections: "dagsedlerKorrektion",
  enableJobberRole: "medarbejdereProfil",
  enableLessorUpload: "lessorModul",
  enableLocationStorage: "lagerModul",
  enableMaanssonsSalaryUpload: "lessorSpecialMaansson",
  enableMachineAnalysis: "rapporterMaskinanalyse",
  enableMachinePriceStructureOverviewSettingsPage: "indstillingerOversigt",
  enableMachineSalaryCostReport: "rapporterMaskinanalyse",
  enableMaterialNoteFields: "opgaverMateriel",
  enableMechanicRole: "medarbejdereProfil",
  enableNoDepartment: "rapporterOpgavestatistik",
  enableOrderMerge: "ordreDetalje",
  enableOrderReferenceNumber: "ordreDetalje",
  enableOrders: "ordreModul",
  enableOrderTaskDepartmentFilter: "opgaverGenerelt",
  enableOrderTaskWorkTypeFilter: "opgaverGenerelt",
  enablePriceItemMinimumCount: "indstillingerPrislinjer",
  enablePriceItemTransferAsMinutes: "fakturaGenerelt",
  enablePriceItemVolumeDiscount: "indstillingerPrislinjer",
  enableProjectDistance: "projekterGenerelt",
  enableProjectManagerRole: "medarbejdereProfil",
  enableProjects: "projekterModul",
  enableProjectTravelTime: "projekterGenerelt",
  enableRecentlyUsedProducts: "opgaverMateriel",
  enableReportGenerator: "rapporterModuler",
  enableRouteJournal: "rapporterRutejournal",
  enableSmallMachines: "indstillingerMaskiner",
  enableStandardMachinesSettingsPage: "indstillingerFavorit",
  enableStandardProductsSettingsPage: "indstillingerFavorit",
  enableTaskEstimation: "opgaverDetaljer",
  enableTaskInternalManagerNotes: "opgaverDetaljer",
  enableTaskLogLocationChange: "logGenerel",
  enableTaskReferenceNumber: "systemReferencenr",
  enableTimeOverviewPage: "minDagsseddelModul",
  enableTransferTasks: "kalenderMedarbejderGenerelt",
  enableTransportLog: "logGammel",
  enableTransportLogStatistics: "logGammel",
  enableTransportReport: "rapporterTransport",
  enableUnitSettingsPage: "indstillinerEnheder",
  enableUnscheduledCalendar: "kalenderMedarbejderOpgaveTopblok",
  enableWorkshopChecklistsFor: "vrkstedTjekliste",
  enableWorkTypePriceStructureOverviewSettingsPage: "indstillingerOversigt",
  enableWorkTypeSettingsPage: "indstillingerOmrder",
  errorOnOnlyPuller: "opgaverUdfrtDialog",
  expectedAmountEnabled: "omrdestatusModul",
  expectedAmountVisibleToMachineOperators: "omrdestatusGenerelt",
  externalTaskCulture: "indstillingerKultur",
  externalTaskCustomer: "kunderModul",
  externalTaskWithoutPriceLinesLegal: "systemGenerelt",
  externalTaskWithoutTaskWorkTypeVariantLegal: "indstillingerGenerelt",
  extraWorkButtonCopiesProject: "opgaverAfslutningsKnapper",
  fieldDefaultLocationType: "indstillingerLokationer",
  fieldDialogOrFiltering: "kunderMarker",
  foodBookingNotificationTime: "madbestillingGenerelt",
  fuelSurcharge: "braendstoftillaegGenerelt",
  fullscreenCalendarSlideshow: "systemInfoskrm",
  fullscreenCalendarSlideshowSeconds: "systemInfoskrm",
  geolocation: "kortModul",
  googleMapsAPIKey: "udgede",
  googleMapsCountryRestrictions: "kortGenerelt",
  hideCompleteAndMakeCopyButton: "opgaverAfslutningsKnapper",
  hideCultureMenuFromMachineOperators: "indstillingerKultur",
  hideCustomerMenuFromMachineOperators: "kunderGenerelt",
  hideInternalTaskListWorkTypes: "opgaverListeInterne",
  hideOrderCalendarTab: "kalenderOrdre",
  hidePrimaryTimeButtonForExternalWorktypes: "tidsknapperGenerelt",
  hidePrimaryTimeButtonForInternalWorktypes: "tidsknapperGenerelt",
  hideTasklistBillingTab: "opgaverFakturering",
  hideUnscheduledCalendarForMachineOperators: "kalenderMedarbejderOpgaveTopblok",
  hideUnscheduledWorkshopTasks: "vrkstedAndet",
  holidaysVisibleToMachineOperators: "kalenderMedarbejderGenerelt",
  hoursAbsenceTypes: "lnFravrstyper",
  ifCountZeroRequireCorrectedCountZero: "dagsedlerKorrektion",
  importAllFieldsPerVatNumber: "indstillingerLokationer",
  importExternalWorkTypesFromProductGroups: "economySystemResourceSync",
  importInternalWorkTypesFromProducts: "economySystemResourceSync",
  includeTaskCancel: "opgaverAfslutningsKnapper",
  includeTaskCopy: "opgaverAfslutningsKnapper",
  includeTransportlogOverviewVariant: "logGenerel",
  industries: "virksomhedLogo",
  informationPageEnabled: "informationModul",
  internalTaskAutoWorkTypePreviousMachines: "opgaverGenerelt",
  internalTaskPhotos: "opgaverInternFoto",
  internalTimeLabel: "udgede",
  invoiceAddGenericTransportLogs: "economySystemInvoice",
  invoiceAddTransportLogSum: "economySystemInvoice",
  invoiceAllowRecordedWithoutInvoiceLines: "dagsedlerKorrektion",
  invoiceExcludeProducts: "economySystemInvoice",
  invoiceExtraEffectiveTimerIdentifiers: "economySystemInvoice",
  invoiceNoteAddAdminNotes: "economySystemInvoiceNotes",
  invoiceNoteAddCustomerContactName: "economySystemInvoiceNotes",
  invoiceNoteAddCustomerDetailsOnTransportLog: "economySystemInvoiceNotes",
  invoiceNoteAddEmployeeAlias: "economySystemInvoiceNotes",
  invoiceNoteAddEmployeeName: "economySystemInvoiceNotes",
  invoiceNoteAddEmployeeNotes: "economySystemInvoiceNotes",
  invoiceNoteAddFieldUse: "economySystemInvoiceNotes",
  invoiceNoteAddFieldUseCrop: "economySystemInvoiceNotes",
  invoiceNoteAddMachineName: "economySystemInvoiceNotes",
  invoiceNoteAddManagerNotes: "economySystemInvoiceNotes",
  invoiceNoteAddOrderManagerNotes: "economySystemInvoiceNotes",
  invoiceNoteAddOrderReferenceNumber: "economySystemInvoiceNotes",
  invoiceNoteAddPickupLocation: "economySystemInvoiceNotes",
  invoiceNoteAddRouteTaskStartStop: "economySystemInvoiceNotes",
  invoiceNoteAddTaskReferenceNumber: "economySystemInvoiceNotes",
  invoiceNoteAddTotalTaskTime: "economySystemInvoiceNotes",
  invoiceNoteAddWorkPlaceOrDeliveryLocation: "economySystemInvoiceNotes",
  invoiceNotePrefixCustomerContactNameWith: "economySystemInvoiceNotes",
  invoiceNotePrefixEmployeeNameOrAliasWith: "economySystemInvoiceNotes",
  invoicesAddTaskNotesToEffectiveTimeLine: "fakturaGenerelt",
  invoiceSplitBy: "economySystemInvoice",
  invoiceSubtractDowntimeTimers: "economySystemInvoice",
  invoiceTaskNotesToEffectiveTimeLine: "economySystemInvoice",
  invoiceUseWorkplaceAddressAsName: "economySystemInvoice",
  jobberRoleCanCreateInternalTasks: "medarbejdereProfil",
  locationCrossCustomerSelectionEnabled: "indstillingerLokationer",
  locationFavoritesEnabled: "indstillingerLokationer",
  locationStorageJournalStatusColumn: "lagerGenerelt",
  locationStoragePageVisibleToEveryone: "lagerGenerelt",
  logButtonText: "logGenerel",
  logDeliveryButtonDisplayCount: "logGenerel",
  logEmailText: "virksomhedEmail",
  logPickupButtonDisplayCount: "logGenerel",
  logRecipients: "logGenerel",
  logWorkplaceDisplayCount: "logGenerel",
  lunchBookingLocations: "madbestillingGenerelt",
  lunchBookings: "madbestillingModul",
  machineAnalysisAccounts: "indstillingerMaskinanalyse",
  machineBeforeMachineOperator: "opgaverDetaljer",
  machineCalendar: "kalenderMaskinerModul",
  machineCalendarForMachineOperators: "kalenderMaskinerModul",
  machineExtraTimers: "tidsknapperGenerelt",
  machineLabelVariant: "systemGenerelt",
  machineOperaterCanEditInvoiceNote: "opgaverFakturering",
  machineOperatorCanCreateCustomerTask: "opgaverGenerelt",
  machineOperatorCanDeleteAssignedTasks: "opgaverGenerelt",
  machineOperatorMayAddTaskPhotosAfterCompletion: "opgaverFoto",
  machineOperatorsCanChooseProject: "opgaverOprettelseProjektRef",
  machineOperatorsCanOnlySeeTheirOwnTasks: "opgaverListeEgne",
  machineOperatorsCanOnlySeeThemselvesOnCalendar: "kalenderMedarbejderGenerelt",
  machineOperatorsCanSeeAbsenceCalendar: "kalenderFravr",
  machineOperatorsCanSeeTheirOwnTasksAndActiveTasks: "opgaverListeEgne",
  machinePriceGroupExtraTimers: "tidsknapperGenerelt",
  managerMenuTasksAfterEmployees: "systemGenerelt",
  mapTaskFormat: "kortGenerelt",
  mapVisibleToAll: "kortGenerelt",
  mapVisibleToJobberRole: "kortGenerelt",
  materialDecimals: "opgaverMateriel",
  materialIssuesErrors: "opgaverMateriel",
  materialUseAlternativeText: "systemGenerelt",
  materialZeroCausesError: "opgaverMateriel",
  missingEffectiveTimeIsError: "opgaverUdfrtDialog",
  navImportCopyCustomerAccountToPhone: "navGenerelt",
  navImportProjects: "navProjekt",
  navSync: "navModul",
  navSyncProfile: "navGenerelt",
  navSyncUploadEnabled: "navGenerelt",
  navTimersToTransferWithoutMachines: "navFakturaDagseddel",
  navTransferEmployeeNotes: "navFakturaDagseddel",
  navVariantTypeImports: "navGenerelt",
  navWorktypeRanges: "navGenerelt",
  noExternalTaskWorkType: "systemGenerelt",
  noProducts: "opgaverMateriel",
  numberOfSalaryVouchersVisibleToEmployees: "minLnbilagOversigt",
  oldTaskWarningAgeMinutes: "opgaverGenerelt",
  oneDayLessVisibleToMachineOperatorsBefore: "opgaverListeEgne",
  onlyAdminCanChangeDepartment: "systemAfdeling",
  onlyAllowEditingNameOnPriceGroupSettingsPage: "indstillingerOversigt",
  onlyEnableProjectsForDepartments: "systemAfdeling",
  onlyManagerCreatedTasksCalendarPlanned: "kalenderMedarbejderGenerelt",
  onlyManagersCanRegisterAbsence: "lnFravrstyper",
  onlyShowCalledInDialogForWorktypes: "systemGenerelt",
  onlyWeekdaysAllowedAbsenceTypes: "lnFravrstyper",
  orderCalendar: "kalenderOrdreModul",
  orderCalendarAsTaskCalendar: "kalenderOpgaverModul",
  orderCalendarAsTaskCalendarBlockExtraInfo: "kalenderOpgaverModul",
  orderCalendarBlockExtraInfo: "kalenderOrdre",
  orderCalendarFoldAllDefault: "kalenderOrdre",
  orderCalendarMachineLinebreak: "kalenderOrdre",
  orderCalendarShowCreatedDate: "kalenderOrdre",
  orderCalendarShowOrderNoteLines: "kalenderOrdre",
  orderCopyFields: "ordreDetaljerKnapper",
  orderDrafts: "ordreKladde",
  orderDraftsAllwaysCreateDraft: "ordreKladde",
  orderDraftsStartOnDraft: "ordreKladde",
  orderEntryAutoOpenCustomerSelection: "ordreGenerelt",
  orderEntryResultsEffective: "ordreDetaljerResultatsum",
  orderEntryResultsWorkplace: "ordreDetaljerResultatsum",
  orderEntryShowContact: "ordreDetalje",
  orderEntryShowPriceGroup: "ordreDetaljerOpgavetabel",
  orderEntryShowResults: "ordreDetaljerResultatsum",
  orderEntryShowWorkPlace: "ordreDetalje",
  orderEntryTaskTableAllwaysShowMachinePriceGroups: "ordreDetaljerOpgavetabel",
  orderEntryTaskTime: "ordreDetalje",
  orderListColumns: "ordreOrdreliste",
  orderListNoteLines: "ordreOrdreliste",
  orderListPageEnabled: "ordreOrdreliste",
  orderReferenceNumberLabel: "ordreDetalje",
  orderShowCustomerFiles: "ordreDetalje",
  orderShowOrderFiles: "ordreDetalje",
  orderTaskTableSort: "ordreDetalje",
  orderValidation: "ordreDetaljerKnapper",
  orderValidationForWorkTypes: "ordreDetaljerKnapper",
  overviewShowPrices: "indstillingerPrislinjer",
  ownCompanyId: "opgaverInfo",
  paidDayAbsenceTypes: "lnFravrstyper",
  periodSplitThresholdMinutes: "lnGenerelt",
  preparationWorkType: "vrkstedGenerelt",
  priceGroupHideTimers: "tidsknapperGenerelt",
  priceItemConversionSetup: "indstillingerPrislinjer",
  priceItemRelationUnitConversionHelpers: "indstillingerPrislinjer",
  productImageSelection: "opgaverMateriel",
  productMultiSelect: "opgaverMateriel",
  productsExcludedFromInvoice: "fakturaGenerelt",
  projectLabelVariant: "projekterModul",
  projectManagerCanManageAbsence: "projekterGenerelt",
  projectMissingCompleteError: "opgaverUdfrtDialogProjektRef",
  projectMissingCompleteWarning: "opgaverUdfrtDialogProjektRef",
  projectMissingValidateError: "opgaverUdfrtDialogProjektRef",
  projectMissingValidateWarning: "opgaverUdfrtDialogProjektRef",
  recordCultureTasks: "indstillingerKultur",
  recordCustomerTasks: "kunderGenerelt",
  recordInternalTasks: "kunderGenerelt",
  registerAbsence: "lnFravrstyper",
  remunerationAbsenceTypes: "lnGenerelt",
  remunerationCalendarDayBonusLabels: "lnGenerelt",
  remunerationCountBonusLabels: "lnGenerelt",
  remunerationDefaultGroup: "lnGenerelt",
  remunerationGroups: "lnGenerelt",
  remunerationIncludeEmptyDatesInReport: "lnGenerelt",
  remunerationIntervalBonusLabels: "lnGenerelt",
  remunerationNormalTransportKilometersCountBonusLabel: "lnGenerelt",
  remunerationPaidTransportWorkType: "lnGenerelt",
  remunerationRateLabels: "lnGenerelt",
  remunerationReportPerVariation: "lnGenerelt",
  remunerationReportShowAlias: "lnGenerelt",
  remunerationReportShowHolidays: "lnGenerelt",
  remunerationReportShowProjects: "lnGenerelt",
  remunerationReportShowReferenceNumbers: "lnGenerelt",
  remunerationStartRateLabel: "lnGenerelt",
  remunerationTaskBonusLabels: "lnGenerelt",
  remunerationVisibleBonusLabels: "lnGenerelt",
  remunerationVisibleRateLabels: "lnGenerelt",
  remunerationWorkDayBonusLabels: "lnGenerelt",
  repairWorkType: "vrkstedAndet",
  reportDataIsValidFrom: "rapporterOpgavestatistik",
  reportDepartmentWorkTypeProductiveCombinations: "udgede",
  reportEffectiveTimeLabel: "rapporterOpgavestatistik",
  reportExtraData: "rapporterOpgavestatistik",
  reportExtraNonProductiveWorkTypeNameToLatexNameMap: "rapporterOpgavestatistik",
  reportHourDecimals: "rapporterOpgavestatistik",
  reportLegendShift: "ukendtGenerelt",
  reportNonProductiveWorkTypeNameToLatexNameMap: "rapporterOpgavestatistik",
  reportSupportedUnits: "rapporterOpgavestatistik",
  requireEmployeeAssignedToTasksWhenConvertingOrderDrafts: "ordreKladde",
  requireFavoriteMachines: "indstillingerFavorit",
  requireFieldForCultureTasks: "opgaverUdfrtDialog",
  requireWorkplaceIfExists: "opgaverInfo",
  rolesMayCreateLocations: "indstillingerLokationer",
  rolesMayEditLocations: "indstillingerLokationer",
  routesEnabled: "ruterModul",
  routeTaskStartStop: "ruterGenerelt",
  salaryVouchersVisibleToEmployees: "mineLnbilagModul",
  salaryVouchersVisibleToManagers: "minLnbilagOversigt",
  selectInformationvisibleTo: "informationGenerelt",
  sendApprovedTransportlogToCustomers: "kunderLog",
  sendValidatedGenericLogToCustomers: "kunderLog",
  seniorMachineOperatorCanEditNotesFromManager: "opgaverGenerelt",
  seniorMachineOperatorCanManageOrders: "opgaverGenerelt",
  setLogOnlyLocationOnCreate: "logGenerel",
  setWorkplaceOnlyLocationOnCreate: "logGenerel",
  sharedTransportLog: "logGammel",
  showAccountOnCustomerList: "kunderListe",
  showAllProjectsOnAppendicesPage: "bilagModul",
  showArrivalAtLocationField: "opgaverDetaljer",
  showAttOnCustomerList: "kunderListe",
  showBrugerdataOrdernumber: "brugerDataGenerelt",
  showC5AccountOnTaskInstanceAnList: "opgaverTopblok",
  showCalledInDialogAfterMinutes: "systemGenerelt",
  showColleaguesAtCustomer: "opgaverInfo",
  showContinueToNextCustomerButton: "opgaverAfslutningsKnapper",
  showCreatedTimestampOnInternalTasks: "opgaverListeInterne",
  showDateGroupsOnOrderLists: "ordreOrdreliste",
  showDateGroupsOnTaskLists: "opgaverListe",
  showEmployeesAndTasksLayersToMachineOperators: "kortGenerelt",
  showFieldNumbersOnCalendar: "kalenderMedarbejderOpgaveBlok",
  showFieldsColumnOnOrderEntryTaskList: "ordreDetaljerOpgavetabel",
  showFieldsOnBookkeepingTables: "dagsedlerMarker",
  showFieldsOnGeolocationMap: "kortGenerelt",
  showGenericPeriodLogView: "kunderLog",
  showHeaderOnTransportLog: "logGammel",
  showInvoiceNote: "opgaverDetaljer",
  showInvoiceNoteOnOrderPdf: "ordreDetalje",
  showLandlinePhoneNumberOnTaskInfo: "opgaverInfo",
  showLogEmailFieldOnCustomerInstance: "kunderLog",
  showLogLocationsOnBookkeeping: "dagsedlerMarker",
  showLogPickupAndDeliveryColumnsOnOrderEntryTaskList: "ordreDetaljerOpgavetabel",
  showMachineAsWorktypeForContratorTasks: "systemAfdeling",
  showMachineLastUse: "opgaverInfo",
  showMachineOperatorInitialsOnTaskInstance: "opgaverTopblok",
  showMachineOperatorNameOnTaskInstance: "opgaverTopblok",
  showManagerInternalNotesOnOrderPDF: "ordreDetalje",
  showManagerInternalOrderNoteLinesOnOrderCalendar: "kalenderOrdre",
  showManagersUnpaidBillWarning: "systemSvend",
  showMaterialAutoLinesToEmployees: "opgaverMateriel",
  showNoteOnInvoiceLineTable: "dagsedlerGenerelt",
  showOrderCreatedDateTime: "ordreOrdreliste",
  showOrderNoteOnTaskList: "opgaverListe",
  showOrderReferenceNumberOnTaskInfoTab: "opgaverInfoProjektRef",
  showOrderWorkplaceOnOrderPdf: "ordreDetalje",
  showOursToggle: "opgaverMateriel",
  showPhotoOnCalendar: "kalenderMedarbejderOpgaveBlok",
  showProductList: "indstillingerVarer",
  showProjectAlias: "projekterGenerelt",
  showProjectInsteadOfCustomer: "kalenderMedarbejderOpgaveBlokProjekt",
  showProjectNameOnTaskInfoTab: "opgaverInfoProjektRef",
  showProjectNameOnTimeOverviewPage: "minDagsseddelProjekt",
  showProjectNumberOnTaskInfoTab: "opgaverInfoProjektRef",
  showProjectNumberOnTaskInstance: "opgaverInfoProjektRef",
  showProjectNumberOnTimeOverviewPage: "minDagsseddelProjekt",
  showProjectOnTaskInstance: "opgaverInfoProjektRef",
  showRealTimeColumnOnOrderPDF: "ordreDetalje",
  showSearchnameOnCustomerList: "kunderListe",
  showStorageOnTaskFieldMap: "kortGenerelt",
  showStorageProductNotOnLocationWarning: "lagerGenerelt",
  showTaskArchiveToMachineOperators: "opgaverListe",
  showTaskEffectiveTimeOnTaskInfoTab: "opgaverInfo",
  showTaskInfoManagerNotesAboveLogButtons: "logGenerel",
  showTaskInvoiceNoteColumnOnOrderEntryTaskList: "ordreDetaljerOpgavetabel",
  showTaskNotesOnOrderEntryPage: "ordreDetaljerOpgavetabel",
  showTaskRefColumnOnOrderEntryTaskList: "ordreDetaljerOpgavetabel",
  showTimeColumnOnOrderEntryTaskList: "ordreDetaljerOpgavetabel",
  showUnscheduledInternalTaskCalendar: "kalenderMedarbejderOpgaveTopblok",
  showWhoHasNotReadInformation: "informationGenerelt",
  showWorkplaceOnTaskInstance: "opgaverTopblok",
  showWorkplacesInCustomerPickupDeliveryLocationDialog: "logGammel",
  showWorkshopMachineName: "vrkstedOpgaver",
  singleDayAbsenceTypes: "lnFravrstyper",
  suggestRecentlyUsedCustomers: "opgaverOprettelseWizard",
  suggestRecentlyUsedWorkTypes: "opgaverOprettelseWizard",
  switchProjectAndCustomerButtonLocation: "opgaverOprettelseProjektRef",
  syncRecipients: "c5Generelt",
  syncResources: "economySystemResourceSync",
  taskAndOrderDatesFromCalendarOnCreate: "kalenderMedarbejderGenerelt",
  taskCalendarFadeCompletedTasks: "kalenderOpgaverModul",
  taskCompleteOverview: "opgaverUdfrtDialog",
  taskCompletePrepareQuery: "opgaverUdfrtDialog",
  taskCompleteRepairQuery: "opgaverUdfrtDialog",
  taskCompleteRepairQueryRequireNote: "opgaverUdfrtDialog",
  taskCopyAlwaysOverridemachineOperator: "opgaverAfslutningsKnapper",
  taskCopyFields: "opgaverAfslutningsKnapper",
  taskCultureSubheader: "opgaverTopblok",
  taskInstancePDF: "opgaverTopblok",
  taskInstancePdfShowWorkplacePickupDelivery: "opgaverTopblok",
  taskListColumns: "opgaverListe",
  taskListeNoteLines: "opgaverListe",
  taskListsMachineOperatorHideCompleted: "opgaverListeEgne",
  taskListsMachineOperatorHideCompletedExceptToday: "opgaverListeEgne",
  taskNoteLinesOnTaskOrderCalender: "kalenderOpgaver",
  taskOrderAutoOpenCustomerSelection: "opgaverOprettelseWizard",
  taskOrderShowContact: "opgaverInfo",
  taskOrderShowDate: "opgaverDetaljer",
  taskOrderShowTime: "opgaverDetaljer",
  taskOrderTaskShowWorkPlace: "opgaverDetaljer",
  taskOverlapWarningAfterMinutes: "kalenderMedarbejderOpgaveBlok",
  taskPageCreatedDate: "opgaverInfo",
  taskPickupDelivery: "opgaverInfo",
  taskReferenceNumberLabel: "systemReferencenr",
  taskShowRelatedFiles: "opgaverFoto",
  taskShowRelatedPhotos: "opgaverFoto",
  taskShowTimeField: "opgaverDetaljer",
  tasksPrintout: "fakturaSomCsv",
  taskWizardAskForWorkPlace: "opgaverOprettelseWizard",
  taskWizardFieldSelection: "opgaverOprettelseWizard",
  timePriceItemsBeforeOtherPriceItems: "opgaverMateriel",
  timersContributingToEffectiveTime: "fakturaGenerelt",
  transferOrderAdminNotesToInvoice: "fakturaGenerelt",
  transferOrderNotesToInvoice: "fakturaGenerelt",
  transferTaskRequireOrderApproval: "economySystemSendTaskRules",
  transferTaskRequireOrderApprovalForWorkTypes: "economySystemSendTaskRules",
  transferTaskUnApproveOnError: "economySystemSendTaskRules",
  transportLogDecimals: "logGammel",
  transportLogDistanceKmDecimalPlaces: "logGammel",
  transportlogTimeHeader: "logGammel",
  transportLogUnitPerLocation: "logGammel",
  transportResponsibleEffectiveTimers: "tidsknapperGenerelt",
  treatTransportTimeAsEffectiveForDepartments: "systemAfdeling",
  unicontaDMEUseTaskProjectForInternalCost: "unicontaDME",
  unicontaDMEWorkingHours: "unicontaDME",
  unicontaMachineDimension: "uniconta",
  unicontaManHoursMachines: "uniconta",
  unicontaPullerDimension: "uniconta",
  unicontaSync: "economySystemActive",
  unicontaVariantDME: "unicontaDME",
  unregisteredBreakAfterMinutes: "lnGenerelt",
  unregisteredWarningAfterMinutes: "kalenderMedarbejderOpgaveBlok",
  unsetValidatedAndRecordedOnError: "dagsedlerGenerelt",
  useApproveReport: "eConomicModul",
  useCalendarWorkHours: "kalenderMedarbejderGenerelt",
  useCorrectedCountOnCostLines: "economySystemSendTaskData",
  useCustomerAddressAsDefaultTaskAddress: "udgede",
  useEmployeeGroups: "kalenderMedarbejderKolonner",
  usePunchInOut: "systemInfoskrm",
  userCellphoneExtra: "medarbejdereProfil",
  userListColumns: "medarbejdereMedarbejderliste",
  userProfileEmployeeNumber: "medarbejdereProfil",
  userProfileNormalTransportKilometers: "medarbejdereProfilLn",
  userProfileNormalTransportKilometersDefault: "medarbejdereProfilLn",
  userProfileNormalTransportMinutes: "medarbejdereProfilLn",
  userProfileNormalTransportMinutesDefault: "medarbejdereProfilLn",
  warnIfNoWorkplace: "opgaverInfo",
  warnOnIncompleteRouteParts: "ruterGenerelt",
  website: "virksomhedStamoplysninger",
  workDaySplitThresholdMinutes: "lnGenerelt",
  workplaceButtonUnderCustomer: "opgaverDetaljer",
  workshopCreatedByColumn: "vrkstedOpgaver",
  workshopCreatedDateColumn: "vrkstedOpgaver",
  workshopEnabled: "vrkstedModul",
  workshopVehicleIdentificationNumber: "vrkstedMaskiner",
  workshopWorkTypes: "vrkstedOpgaver",
  workTypeAutoMachines: "indstillingerGenerelt",
  workTypeBeforeTimerBeforeMachinePriceItems: "opgaverMateriel",
  workTypeExtraTimers: "tidsknapperGenerelt",
  workTypeGenericPrimaryTimerLabel: "tidsknapperGenerelt",
  workTypeHideTimers: "tidsknapperGenerelt",
  workTypeMachineExtraTimers: "tidsknapperGenerelt",
  workTypePriceGroupExtraTimers: "tidsknapperGenerelt",
  workTypePriceGroupGenericPrimaryTimerLabel: "tidsknapperGenerelt",
  yieldLogDefaultCrop: "logGammel",
  yieldLogDefaultUnit: "logGammel",
};
