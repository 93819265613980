import {ReadonlyConfig} from "@co-common-libs/config";
import {Project, ProjectAccess, Role} from "@co-common-libs/resources";
import {
  EconomicProjectSyncEnabledCustomerSettings,
  economicProjectImportEnabled,
  roleIsManager,
  roleIsProjectManager,
} from "@co-common-libs/utils";
import {SyncedResourceHandleKey} from "integration-constants";
import {
  ActiveEconomySystemIntegrationKind,
  EconomySystemIntegrationKindPart,
  getEconomySystemIntegrationKind,
} from "../get-economy-system-integration-kind";

type ExtendedProjectSettingsPart = Pick<
  ReadonlyConfig,
  | "canAddProject"
  | "canEditProjectDistance"
  | "canEditProjects"
  | "createCostLines"
  | "enableProjectDistance"
  | "enableProjects"
  | "enableProjectTravelTime"
  | "projectLabelVariant"
  | "showProjectAlias"
  | "syncResources"
> &
  EconomicProjectSyncEnabledCustomerSettings &
  EconomySystemIntegrationKindPart;

export interface ExtendedProjectSettings
  extends Pick<
    ReadonlyConfig,
    "enableProjectDistance" | "enableProjectTravelTime" | "projectLabelVariant" | "showProjectAlias"
  > {
  canCreate: boolean;
  canEditField: (project: Pick<Project, "access" | "remoteUrl">, field: keyof Project) => boolean;
  canOpenEditDialog: boolean;
  requireIntegerProjectNumber: boolean;
  useInternalProjects: boolean;
}

const externalDataMembers: ReadonlySet<keyof Project> = new Set([
  "remoteUrl",
  "access",
  "customer",
  "name",
  "projectNumber",
]);

export function extendedProjectSettings(
  config: ExtendedProjectSettingsPart,
  role: Role | null,
): ExtendedProjectSettings {
  const {
    c5Sync,
    canAddProject,
    canEditProjectDistance,
    canEditProjects,
    economicSync,
    enableProjectDistance,
    enableProjects,
    enableProjectTravelTime,
    projectLabelVariant,
    showProjectAlias,
    syncResources,
  } = config;
  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);

  const useInternalProjects =
    (enableProjects &&
      !c5Sync &&
      !economicSync &&
      !syncResources.includes("project" satisfies SyncedResourceHandleKey)) ||
    (economicSync && !economicProjectImportEnabled(config));

  const integrationAllowsManage =
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.NONE;

  const canCreate =
    enableProjects &&
    (canAddProject === "all" ||
      (canAddProject === "manager" && (roleIsManager(role) || roleIsProjectManager(role)))) &&
    (useInternalProjects || integrationAllowsManage);

  const canEditExternalData =
    canEditProjects &&
    (roleIsManager(role) || roleIsProjectManager(role)) &&
    integrationAllowsManage;

  return {
    canCreate,
    canEditField: (project: Pick<Project, "access" | "remoteUrl">, field: keyof Project) => {
      let passAdditionalFieldRequirements = true;

      if (field === "distanceInKm") {
        passAdditionalFieldRequirements = canEditProjectDistance;
      }
      if (!project.remoteUrl) {
        return roleIsManager(role) && passAdditionalFieldRequirements;
      } else {
        const isInternalField = !externalDataMembers.has(field);
        return (
          project.access !== ProjectAccess.Barred &&
          (canEditExternalData || isInternalField) &&
          roleIsManager(role) &&
          passAdditionalFieldRequirements
        );
      }
    },
    canOpenEditDialog: enableProjects && canEditProjects,
    enableProjectDistance,
    enableProjectTravelTime,
    projectLabelVariant,
    requireIntegerProjectNumber: economicSync,
    showProjectAlias,
    useInternalProjects,
  };
}
